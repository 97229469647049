/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const createDeliverer = /* GraphQL */ `
  mutation CreateDeliverer(
    $input: CreateDelivererInput!
    $condition: ModelDelivererConditionInput
  ) {
    createDeliverer(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDeliverer = /* GraphQL */ `
  mutation UpdateDeliverer(
    $input: UpdateDelivererInput!
    $condition: ModelDelivererConditionInput
  ) {
    updateDeliverer(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDeliverer = /* GraphQL */ `
  mutation DeleteDeliverer(
    $input: DeleteDelivererInput!
    $condition: ModelDelivererConditionInput
  ) {
    deleteDeliverer(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createDirector = /* GraphQL */ `
  mutation CreateDirector(
    $input: CreateDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    createDirector(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDirector = /* GraphQL */ `
  mutation UpdateDirector(
    $input: UpdateDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    updateDirector(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDirector = /* GraphQL */ `
  mutation DeleteDirector(
    $input: DeleteDirectorInput!
    $condition: ModelDirectorConditionInput
  ) {
    deleteDirector(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteStaff = /* GraphQL */ `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createOrderState = /* GraphQL */ `
  mutation CreateOrderState(
    $input: CreateOrderStateInput!
    $condition: ModelOrderStateConditionInput
  ) {
    createOrderState(input: $input, condition: $condition) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateOrderState = /* GraphQL */ `
  mutation UpdateOrderState(
    $input: UpdateOrderStateInput!
    $condition: ModelOrderStateConditionInput
  ) {
    updateOrderState(input: $input, condition: $condition) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteOrderState = /* GraphQL */ `
  mutation DeleteOrderState(
    $input: DeleteOrderStateInput!
    $condition: ModelOrderStateConditionInput
  ) {
    deleteOrderState(input: $input, condition: $condition) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createUniversity = /* GraphQL */ `
  mutation CreateUniversity(
    $input: CreateUniversityInput!
    $condition: ModelUniversityConditionInput
  ) {
    createUniversity(input: $input, condition: $condition) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUniversity = /* GraphQL */ `
  mutation UpdateUniversity(
    $input: UpdateUniversityInput!
    $condition: ModelUniversityConditionInput
  ) {
    updateUniversity(input: $input, condition: $condition) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUniversity = /* GraphQL */ `
  mutation DeleteUniversity(
    $input: DeleteUniversityInput!
    $condition: ModelUniversityConditionInput
  ) {
    deleteUniversity(input: $input, condition: $condition) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createVenue = /* GraphQL */ `
  mutation CreateVenue(
    $input: CreateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    createVenue(input: $input, condition: $condition) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteVenue = /* GraphQL */ `
  mutation DeleteVenue(
    $input: DeleteVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    deleteVenue(input: $input, condition: $condition) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createEventException = /* GraphQL */ `
  mutation CreateEventException(
    $input: CreateEventExceptionInput!
    $condition: ModelEventExceptionConditionInput
  ) {
    createEventException(input: $input, condition: $condition) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateEventException = /* GraphQL */ `
  mutation UpdateEventException(
    $input: UpdateEventExceptionInput!
    $condition: ModelEventExceptionConditionInput
  ) {
    updateEventException(input: $input, condition: $condition) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteEventException = /* GraphQL */ `
  mutation DeleteEventException(
    $input: DeleteEventExceptionInput!
    $condition: ModelEventExceptionConditionInput
  ) {
    deleteEventException(input: $input, condition: $condition) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createEventMenu = /* GraphQL */ `
  mutation CreateEventMenu(
    $input: CreateEventMenuInput!
    $condition: ModelEventMenuConditionInput
  ) {
    createEventMenu(input: $input, condition: $condition) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateEventMenu = /* GraphQL */ `
  mutation UpdateEventMenu(
    $input: UpdateEventMenuInput!
    $condition: ModelEventMenuConditionInput
  ) {
    updateEventMenu(input: $input, condition: $condition) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteEventMenu = /* GraphQL */ `
  mutation DeleteEventMenu(
    $input: DeleteEventMenuInput!
    $condition: ModelEventMenuConditionInput
  ) {
    deleteEventMenu(input: $input, condition: $condition) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createMenuFood = /* GraphQL */ `
  mutation CreateMenuFood(
    $input: CreateMenuFoodInput!
    $condition: ModelMenuFoodConditionInput
  ) {
    createMenuFood(input: $input, condition: $condition) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateMenuFood = /* GraphQL */ `
  mutation UpdateMenuFood(
    $input: UpdateMenuFoodInput!
    $condition: ModelMenuFoodConditionInput
  ) {
    updateMenuFood(input: $input, condition: $condition) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteMenuFood = /* GraphQL */ `
  mutation DeleteMenuFood(
    $input: DeleteMenuFoodInput!
    $condition: ModelMenuFoodConditionInput
  ) {
    deleteMenuFood(input: $input, condition: $condition) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createFood = /* GraphQL */ `
  mutation CreateFood(
    $input: CreateFoodInput!
    $condition: ModelFoodConditionInput
  ) {
    createFood(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateFood = /* GraphQL */ `
  mutation UpdateFood(
    $input: UpdateFoodInput!
    $condition: ModelFoodConditionInput
  ) {
    updateFood(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteFood = /* GraphQL */ `
  mutation DeleteFood(
    $input: DeleteFoodInput!
    $condition: ModelFoodConditionInput
  ) {
    deleteFood(input: $input, condition: $condition) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createTimeSlot = /* GraphQL */ `
  mutation CreateTimeSlot(
    $input: CreateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    createTimeSlot(input: $input, condition: $condition) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeSlot = /* GraphQL */ `
  mutation UpdateTimeSlot(
    $input: UpdateTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    updateTimeSlot(input: $input, condition: $condition) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeSlot = /* GraphQL */ `
  mutation DeleteTimeSlot(
    $input: DeleteTimeSlotInput!
    $condition: ModelTimeSlotConditionInput
  ) {
    deleteTimeSlot(input: $input, condition: $condition) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAvailability = /* GraphQL */ `
  mutation CreateAvailability(
    $input: CreateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    createAvailability(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateAvailability = /* GraphQL */ `
  mutation UpdateAvailability(
    $input: UpdateAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    updateAvailability(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteAvailability = /* GraphQL */ `
  mutation DeleteAvailability(
    $input: DeleteAvailabilityInput!
    $condition: ModelAvailabilityConditionInput
  ) {
    deleteAvailability(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createAvailabilityException = /* GraphQL */ `
  mutation CreateAvailabilityException(
    $input: CreateAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    createAvailabilityException(input: $input, condition: $condition) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAvailabilityException = /* GraphQL */ `
  mutation UpdateAvailabilityException(
    $input: UpdateAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    updateAvailabilityException(input: $input, condition: $condition) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAvailabilityException = /* GraphQL */ `
  mutation DeleteAvailabilityException(
    $input: DeleteAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    deleteAvailabilityException(input: $input, condition: $condition) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
